import { IS_LOCAL, IS_PROD } from "./constants";
import { isAnalyticsPermittedByUser } from "./cookies";
import { createScript } from "../utils/useScript";

const isQuantumMetricEnabled = () =>
  import.meta.env.VITE_QUANTUM_METRICS_ENABLED === "true";

export const initQuantumMetric = () => {
  if (IS_LOCAL || !isQuantumMetricEnabled() || !isAnalyticsPermittedByUser()) {
    return;
  }

  const src = `https://cdn.quantummetric.com/qscripts/quantum-herbalife${IS_PROD ? "" : "-test"}.js`;
  const qtm = createScript(src);
  const d = document.getElementsByTagName("script")[0];
  if (!window.QuantumMetricAPI) d?.parentNode?.insertBefore(qtm, d);
};
