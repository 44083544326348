/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { FieldValues } from "react-hook-form";
import { useAttachmentsStore } from "./attachmentsStore";

export const createAttachmentsFromForm = (personalInfoValues: FieldValues) => {
  return {
    attachments: Object.entries(personalInfoValues)
      .filter(([key]) => key.includes("attachment"))
      .map(([name, attachment]) => {
        return {
          fileMetadata: {
            name: attachment?.file?.name,
            type: attachment?.file?.type,
          },
          awsKey: attachment?.awsKey,
          wasUploaded: attachment?.wasUploaded,
          name: name,
          type: attachment?.type,
        };
      }),
  };
};

export const attachmentsFromStore = () => {
  return Object.fromEntries(
    useAttachmentsStore.getState().attachments.map((attachment) => [
      attachment.name,
      {
        file: attachment.fileMetadata
          ? new File([], attachment?.fileMetadata?.name || "", {
              type: attachment?.fileMetadata?.type,
            })
          : undefined,
        awsKey: attachment?.awsKey,
        wasUploaded: attachment?.wasUploaded,
        type: attachment?.type,
      },
    ])
  );
};

const getAttachmentKeys = (personalInfoValues: FieldValues) =>
  Object.entries(personalInfoValues)
    .filter(([key]) => key.includes("attachment"))
    .filter(([, attachment]) => attachment.awsKey)
    .map(([, attachment]) => attachment.awsKey);

const getAttachmentsForRequest = (personalInfoValues: FieldValues) =>
  Object.entries(personalInfoValues)
    .filter(([key]) => key.includes("attachment"))
    .filter(([, attachment]) => attachment.awsKey)
    .map(([, attachment]) => {
      return { fileName: attachment.awsKey, type: attachment.type };
    });

export const createAttachmentKeysRequestFromForm = (
  personalInfoValues: FieldValues
) => {
  return {
    attachmentKeys: getAttachmentKeys(personalInfoValues),
    attachments: getAttachmentsForRequest(personalInfoValues),
  };
};
